import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import ActivityPage from './ActivityPage';
import TicketPage from './ticket/TicketPage';
import { SearchPage as TicketSearchPage } from './ticket/SearchPage';
import { Selector } from '@utils/constans';
import DemoPage from './demo/DemoPage';
import { useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import ActivityDetailsPage from './ActivityDetailsPage';

function Index() {
    const fetching = useSelector((state) => state.activities.fetching);
    return (
        <>
            <Routes>
                <Route
                    path="/tickets/*"
                    exact
                    element={
                        <Routes>
                            <Route path="" exact element={<TicketPage />} />
                            <Route path="/search" exact element={<TicketSearchPage />} />
                        </Routes>
                    }
                />
                <Route
                    path="/demo/*"
                    exact
                    element={
                        <Routes>
                            <Route
                                path=""
                                exact
                                element={<DemoPage title="定制参考" fixedSelector={Selector.AustralianTourItinerary} />}
                            />
                        </Routes>
                    }
                />
                <Route
                    path="/cruises/*"
                    exact
                    element={
                        <Routes>
                            <Route
                                path=""
                                exact
                                element={<DemoPage title="南太平洋邮轮" fixedSelector={Selector.Cruises} />}
                            />
                        </Routes>
                    }
                />
                {/* <Route path="/:id" exact element={<ActivityPage />} /> */}
                <Route path="/:id" exact element={<ActivityDetailsPage />} />
            </Routes>
            <Dimmer active={fetching} page inverted>
                <Loader></Loader>
            </Dimmer>
        </>
    );
}

export default Index;
