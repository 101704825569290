import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/SearchLayout.module.css';
import { DestinationName } from '@utils/constans';
import ActivitiesGrid from './ActivitiesGrid';
import { useSearchParams } from 'react-router-dom';
import { Result } from 'antd';

function Activities({ activities = [], filterElement, priceSorting = true, loaded = true }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [title, setTitle] = useState('');
    const selectRef = useRef();

    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        const selector = params.get('selector') || '';
        const sort = params.get('sort') || '';
        setTitle(DestinationName(selector));
        if (!!selectRef.current) {
            selectRef.current.value = sort;
        }
    }, [searchParams]);

    const handleOnChangeFilter = (e) => {
        const name = e.target.getAttribute('name');
        let value = e.target.getAttribute('value');
        // workaround select tag
        if (!!value == false) {
            value = e.target.value;
        }
        // console.log({ [name]: value });
        const params = new URLSearchParams(searchParams);
        switch (name) {
            case 'sort':
                if (value) {
                    params.set('sort', value);
                } else {
                    params.delete('sort');
                }
                setSearchParams(params, { replace: true });
            default:
                break;
        }
    };

    const Content = () => {
        if (loaded) {
            if (activities?.length > 0) {
                return (
                    <div className={styles.grid_container}>
                        <ActivitiesGrid activities={activities} />
                    </div>
                );
            } else {
                return <Result title={<p style={{ fontSize: 16 }}>未找到活动体验，请尝试其他搜索条件</p>} />;
            }
        } else {
            return <div style={{ textAlign: 'center' }}>Loading...</div>;
        }
    };

    return (
        <div className={styles.container}>
            {priceSorting && (
                <div className={styles.top_container}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.sort_container}>
                        <select name="sort" ref={selectRef} onChange={handleOnChangeFilter}>
                            <option value="">默认排序</option>
                            <option value="price-desc">价格(从高到低)</option>
                            <option value="price-asc">价格(从低到高)</option>
                        </select>
                    </div>
                </div>
            )}
            <div className={styles.bottom_container}>
                {!!filterElement && <section className={styles.leading_container}>{filterElement}</section>}
                <section className={styles.trailing_container}>
                    <Content />
                </section>
            </div>
        </div>
    );
}

Activities.propTypes = {
    activities: PropTypes.array.isRequired,
    filterElement: PropTypes.element,
    priceSorting: PropTypes.bool,
    loaded: PropTypes.bool,
};

export default Activities;
