import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/ActivitiesGrid.module.css';
import * as config from '@config';
import CommissionPrint from '../CommissionPrint';
import { GetUserInfo } from '@utils/storage';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RightCircleOutlined } from '@ant-design/icons';

function ActivitiesGrid({ activities = [], isDailyUnit = false, isFromPrice = false }) {
    return (
        <div className={styles.container}>
            <div className={styles.grid_container}>
                {activities.map((activity, index) => {
                    return (
                        <ActivitiesGridItem
                            key={index}
                            activity={activity}
                            isDailyUnit={isDailyUnit}
                            isFromPrice={isFromPrice}
                        />
                    );
                })}
            </div>
        </div>
    );
}

ActivitiesGrid.propTypes = {
    activities: PropTypes.array.isRequired,
    isDailyUnit: PropTypes.bool,
    isFromPrice: PropTypes.bool,
};

//Grid Item
function ActivitiesGridItem({ activity = {}, isDailyUnit = false, isFromPrice = false }) {
    const loginInfo = GetUserInfo();
    const [commission, setCommission] = useState({});

    const commissionProfile = useSelector((state) => state.commission.data);

    useEffect(() => {
        if (activity.id && loginInfo.agency.id) {
            let comm = commissionProfile?.adjusted?.find((item) => item.activityID == activity.id);
            if (!comm) {
                comm = { ratio: commissionProfile.defaultRate };
            }
            setCommission(comm);
        }
        return () => {};
    }, [activity.id, commissionProfile]);

    return (
        <div className={styles.grid_item_container}>
            <Link to={`/dashboard/activities/${activity.id}`}>
                <img
                    src={`${config.baseURL}/public/${activity.photos?.[0]?.filename}`}
                    onError={(e) => (e.currentTarget.src = `${config.baseURL}/public/empty_image.png`)}
                />
            </Link>
            <div className={styles.trailing_container}>
                <div className={styles.title}>
                    {activity.name} {activity.tourCode && `(${activity.tourCode})`}
                </div>
                <div className={styles.pricing_container}>
                    {activity.demo != true && (
                        <>
                            {activity.pricing.bundlePriced && (
                                <div className={styles.price}>
                                    $A{activity.pricing.bundlePrice}
                                    {isDailyUnit && '/天'} {isFromPrice && '起'}
                                </div>
                            )}
                            {!activity.pricing.bundlePriced && (
                                <>
                                    <div className={styles.price}>
                                        $A{activity.pricing.adultPrice}
                                        {isDailyUnit && '/天'} {isFromPrice && '起'}
                                    </div>
                                    {activity.pricing.childPrice && (
                                        <div className={styles.child_price}>
                                            {' '}
                                            (儿童: $A{activity.pricing.childPrice}){isDailyUnit && '/天'}{' '}
                                            {isFromPrice && '起'}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                {activity.demo != true ? (
                    <div className={styles.commission}>
                        <CommissionPrint commission={commission} defaultRate={loginInfo.agency.defaultCommission} />
                    </div>
                ) : (
                    <Link to={`/dashboard/activities/${activity.id}`}>
                        <div className={styles.demo}>
                            <div>参考行程</div>
                            <RightCircleOutlined />
                        </div>
                    </Link>
                )}
            </div>
        </div>
    );
}

ActivitiesGridItem.propTypes = {
    activity: PropTypes.object.isRequired,
    isDailyUnit: PropTypes.bool,
    isFromPrice: PropTypes.bool,
};

export default ActivitiesGrid;
